<script>
import { required, email } from "vuelidate/lib/validators";
import userManagerApi from "@/api/userManagerApi";

export default {
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      isOverLayShow: false,
      alertSettings: {
        dismissCountDown: 0,
        countDownSecs: 4,
        title: "",
        alertMsg: "",
        alertVariant: "warning"
      }
    };
  },
  validations: {
    email: { required, email }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.isOverLayShow = true;
        this.tryingToReset = true;
        // Reset the authError if it existed.
        this.error = null;
        let verifiedEmail = this.email;
        userManagerApi
          .forgetPassword(verifiedEmail)
          .then(res => {
            console.info(res);
            this.tryingToReset = false;
            this.isResetError = false;
            this.isOverLayShow = false;
            if (res.data.success) {
              this.setAlertMessage(
                "success",
                "",
                "An email sent to you with a link to change your password.",
                15
              );
            } else {
              this.setAlertMessage(
                "danger",
                "",
                res.data.errors.errors[0].stringValue,
                15
              );
            }
          })
          .catch(error => {
            this.tryingToReset = false;
            this.error = error ? error : "";
            this.isResetError = true;
          });
      }
    },
    countDownChanged(dismissCountDown) {
      this.alertSettings.dismissCountDown = dismissCountDown;
    },
    setAlertMessage(type, title, message, countDown) {
      this.alertSettings.title = title;
      this.alertSettings.alertMsg = message;
      this.alertSettings.alertVariant = type;
      this.alertSettings.dismissCountDown =
        this.alertSettings.countDownSecs + countDown;
    }
  }
};
</script>

<template>
  <b-overlay :show="isOverLayShow" rounded="sm">
    <div>
      <b-alert
        :show="alertSettings.dismissCountDown"
        class="position-fixed fixed-top m-0 rounded-0 text-center"
        style="z-index: 2000;"
        :variant="alertSettings.alertVariant"
        dismissible
        @dismiss-count-down="countDownChanged"
      >
        <!--		<div v-html="alertSettings.title"></div>-->
        <div v-html="alertSettings.alertMsg"></div>
      </b-alert>
      <div class="home-btn d-none d-sm-block">
        <a href="/">
          <i class="mdi mdi-home-variant h2 text-white"></i>
        </a>
      </div>
      <div>
        <div class="container-fluid p-0">
          <div class="row no-gutters">
            <div class="col-lg-4">
              <div
                class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
              >
                <div class="w-100">
                  <div class="row justify-content-center">
                    <div class="col-lg-9">
                      <div>
                        <div class="text-center">
                          <!--                        <div>-->
                          <!--                          <a href="/" class="logo">-->
                          <!--                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />-->
                          <!--                          </a>-->
                          <!--                        </div>-->

                          <h4 class="font-size-18 mt-4">Reset Password</h4>
                          <!--                        <p class="text-muted">Reset your password to Nazox.</p>-->
                        </div>

                        <div class="p-2 mt-5">
                          <b-alert
                            v-model="isResetError"
                            class="mb-4"
                            variant="danger"
                            dismissible
                            >{{ error }}</b-alert
                          >

                          <form
                            class="form-horizontal"
                            @submit.prevent="tryToReset"
                          >
                            <div class="form-group auth-form-group-custom mb-4">
                              <i
                                class="ri-mail-line auti-custom-input-icon"
                              ></i>
                              <label for="useremail">Email</label>
                              <input
                                v-model="email"
                                type="email"
                                class="form-control"
                                id="useremail"
                                placeholder="Enter email"
                                :class="{
                                  'is-invalid': submitted && $v.email.$error
                                }"
                              />
                              <div
                                v-if="submitted && $v.email.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.email.required"
                                  >Email is required.</span
                                >
                                <span v-if="!$v.email.email"
                                  >Please enter valid email.</span
                                >
                              </div>
                            </div>

                            <div class="mt-4 text-center">
                              <button
                                class="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                            </div>
                          </form>
                        </div>

                        <div class="mt-5 text-center">
                          <p>
                            Already have an account?
                            <router-link
                              tag="a"
                              to="/login"
                              class="font-weight-medium text-primary"
                              >Log in</router-link
                            >
                          </p>
                          <p>
                            © 2023 Jebsen Travel
                            <!--                          <i class="mdi mdi-heart text-danger"></i> by-->
                            <!--                          Themesdesign-->
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="authentication-bg">
                <div class="bg-overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
